import { createRouter, createWebHistory } from 'vue-router'


const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue')
  },
  {
    path: '/showroom',
    name: 'showroom',
    component: () => import(/* webpackChunkName: "showroom" */ '../views/Showroom.vue')
  },
  {
    path: '/instalaciones',
    name: 'instalaciones',
    component: () => import(/* webpackChunkName: "instalaciones" */ '../views/Instalaciones.vue')
  },
  {
    path: '/torneos',
    name: 'torneos',
    component: () => import(/* webpackChunkName: "torneos" */ '../views/Torneos.vue')
  },
  {
    path: '/bar',
    name: 'bar',
    component: () => import(/* webpackChunkName: "bar" */ '../views/Bar.vue')
  },
  {
    path: '/bar/carrito',
    name: 'carrito',
    component: () => import(/* webpackChunkName: "bar" */ '../components/Carrito.vue')
  },
  {
    path: '/contacto',
    name: 'contacto',
    component: () => import(/* webpackChunkName: "contacto" */ '../views/Contacto.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
