import { createStore } from 'vuex'

export default createStore({
  state: { 
    productos: [],
    productosFiltrados: [],
    minutas: [],
    carrito: {},
    torneos: []
  },
  getters: {
    totalCantidad(state){
      return Object.values(state.carrito).reduce((acc, {cantidad})=> acc + cantidad, 0)
    },
    totalPrecio(state){
      return Object.values(state.carrito).reduce((acc, {cantidad, precio})=> acc + precio * cantidad, 0)
    }
  },
  mutations: {
    setProductos(state, payload){
      state.productos = payload
    },
    setProductosFiltrados(state, payload){
      state.productosFiltrados = payload
    },
    setTorneos(state, payload){
      state.torneos = payload
    },
    setMinutas(state, payload){
      state.minutas = payload
    },
    setCarrito(state, payload){
      state.carrito[payload.id] = payload
    }, 
    vaciarCarrito(state){
      state.carrito = {}
    },
    aumentar(state, payload){
      state.carrito[payload].cantidad = state.carrito[payload].cantidad + 1
    }, 
    disminuir(state, payload){
      state.carrito[payload].cantidad = state.carrito[payload].cantidad - 1
      if (state.carrito[payload].cantidad === 0 ){
        delete state.carrito[payload]
      }
    }
  },
  actions: {
    async getProductos({commit}){
      try {
        const res = await fetch('api.json')
        const data = await res.json()
        commit('setProductos', data)
      } catch (error) {
        console.log(error)
      }
    },
    filtrarCategoria({commit, state}, categoria){
      const filtro = state.productos.filter(producto => 
          producto.categoria.includes(categoria)
        ) 
      commit('setProductosFiltrados', filtro)  
    },
    filtroBuscador({commit, state}, texto){
      const textoBuscador = texto.toLowerCase()
      const filtro = state.productos.filter( producto => {
        const textoApi = producto.titulo.toLowerCase()
        if(textoApi.includes(textoBuscador)){
          return producto
        }
      })
      commit('setProductosFiltrados', filtro)
    },
    async fetchData({commit}){
      try {
        const res = await fetch('bar.json')
        const data = await res.json()
        commit('setMinutas', data)
      } catch (error) {
        console.log(error)
      }
    },
    agregarAlCarro({commit, state}, minuta){
      state.carrito.hasOwnProperty(minuta.id) ? minuta.cantidad = state.carrito[minuta.id].cantidad + 1 : minuta.cantidad = 1
      commit('setCarrito', minuta)
    },
    async getTorneos({commit}){
      try {
        const res = await fetch('torneos.json')
        const data = await res.json()
        commit('setTorneos', data)
      } catch (error) {
        console.log(error)
      }
    }
  },
  modules: {
  }
})
