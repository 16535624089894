<template>
  <Navbar />
  <div class="contenedor-img">
    <img src="./assets/logo-antoca2.jpg" alt="antoca-padel" class="imagen-antoca">
  </div>
  <router-view/>
</template>

<script>  
import Navbar from './components/Navbar.vue'
export default {
  components: {
    Navbar
  }
}
</script>

<style>
.contenedor-img{
  background-color: black;
  text-align: center;
  height: 20vh;
}
.imagen-antoca{
  height: 20vh;
}

</style>