<template>
    <nav class="navbar navbar-expand-lg" style="background-color: #e72caf">
        <div class="container-fluid ">
            <router-link to="/" class="navbar-brand" @click="closeNavbar"><img src="https://antoca-padel.s3.amazonaws.com/nav.png" width="40" height="40"></router-link>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
            <ul class="navbar-nav">
                <li class="nav-item">
                <router-link to="/instalaciones" class="nav-link" aria-current="page" @click="closeNavbar">Instalaciones</router-link>
                </li>
                <li class="nav-item">
                <router-link to="/torneos" class="nav-link" @click="closeNavbar">Torneos</router-link>
                </li>
                <li class="nav-item">
                <router-link to="/bar" class="nav-link" @click="closeNavbar">Bar</router-link>
                </li> 
                <li class="nav-item">
                <router-link to="/showroom" class="nav-link" @click="closeNavbar">Showroom</router-link>
                </li>
                <li class="nav-item">
                <router-link to="/contacto" class="nav-link" @click="closeNavbar">Contacto</router-link>
                </li>
            </ul>
            </div>
        </div>
    </nav>
</template>
<script>
export default {
  methods: {
    closeNavbar() {
      let navbar = document.querySelector('.navbar-collapse');
      if (navbar.classList.contains('show')) {
        navbar.classList.remove('show');
      }
    }
  }
}
</script>